.comingSoon > p {
  max-Height: 10vh;
  margin: 3.5em auto;
  display: block;
  text-align: center;
}

.productImage {
  max-Height: 40vh;
  margin: 1em auto;
  display: block;
  border: 1px black solid;
  border-radius: 0.45em;
}
